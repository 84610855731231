<template>
  <div class="pt-16">
    <div
      class="w-full h-50vh md:h-25vh lg:h-50vh px-4 md:px-0 py-12 mx-auto text-left bg-fixed bg-contain bg-no-repeat flex items-center justify-end"
      :style="`background-image: url(${bgImage})`"
    >
      <!-- <div class="w-4/12 py-12 px-4 md:px-0 text-4xl font-semibold">
        <div class="w-max mx-auto p-4 text-secondary">
          Elementary School
        </div>
      </div> -->
    </div>
    <div
      class="md:w-768 lg:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div class="w-full text-textBlack text-left">
        <!-- <h1 class="mb-6 text-4xl text-primary font-semibold">
          neo’s testing tools​
        </h1> -->
        <h1 class="mb-2 text-2xl text-primary font-semibold">
          neo for Elementary School​
        </h1>
        <!-- <h3 class="text-xl">
          With neo’s testing tools, its easy to get started and keep your
          learners on the right path.​
        </h3> -->
        <p class="text-base text-justify">
          With neo, elementary school students will first focus on developing
          the language base that is a prerequisite for effective literacy. As
          they advance, the focus turns to developing 4 language skills, and a
          stronger focus on reading and grammar that prepares them for the
          academic English required in middle school.​ ​
        </p>
        <h1 class="mt-10 mb-0 text-xl text-primary font-semibold text-center">
          Elementary School Course
        </h1>
        <ul
          class="flex flex-wrap md:flex-nowrap justify-between text-textBlack"
        >
          <li class="w-full md:w-3/12 bg-softGrey py-4 px-6 mt-2 md:mt-6">
            <h5
              class="h-16 md:h-20 lg:h-16 font-semibold text-pulpBlue text-center text-lg"
            >
              11 Certificate Levels​​
            </h5>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              Learners are placed in one of 11 CEFR levels based on current
              skills. Each certificate can be completed in 36 hours or less.​
            </p>
          </li>
          <li
            class="w-full md:w-3/12 bg-softGrey py-4 px-6 md:mx-2 mt-2 md:mt-6"
          >
            <h5
              class="h-16 md:h-20 lg:h-16 font-semibold text-pulpBlue text-center text-lg"
            >
              Speech Recognition Exercises ​
            </h5>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              Students can practice their pronunciation skills with neo’s
              advanced speech recognition (SR) technology. SR exercises
              continuously improve learners’ oral skills. ​
            </p>
          </li>
          <li
            class="w-full md:w-3/12 bg-softGrey py-4 px-6 md:mr-2 mt-2 md:mt-6"
          >
            <h5
              class="h-16 md:h-20 lg:h-16 font-semibold text-pulpBlue text-center text-lg"
            >
              Mastery Tests​
            </h5>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              neo’s Mastery Tests confirm a student’s grasp of key CEFR 'can-do
              statements' studied so far. Each unit continues until all CEFR
              level 'can-do statements' are achieved​.
            </p>
          </li>
          <li class="w-full md:w-3/12 bg-softGrey py-4 px-6 mt-2 md:mt-6">
            <h5
              class="h-16 md:h-20 lg:h-16 font-semibold text-pulpBlue text-center text-lg"
            >
              Level Tests​​
            </h5>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              At the end of each level, learners take a Certificate Test
              covering key language and concepts from the entire CEFR level.​
            </p>
          </li>
        </ul>
      </div>
    </div>
    <ul class="mb-20 flex flex-col items-center justify-center">
      <li class="mb-4">
        <a
          class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
          href="https://cms.nexgenenglishonline.co/uploads/files/A1%20Nexgen%20Website%20Media%20Lib/Brochures%20NW/neoPrep%2B%20Brochure.pdf"
          target="_blank"
        >
          Download more info
        </a>
      </li>
      <li>
        <a
          class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
          href="https://cms.nexgenenglishonline.co/uploads/files/A1%20Nexgen%20Website%20Media%20Lib/Brochures%20NW/neoJr%2B%20Skill%20Level%20Correlation.pdf"
          target="_blank"
        >
          neoJr+ Skill Level Correlations
        </a>
      </li>
      <!-- <li
        class="bg-softBlue text-secondary px-4 py-1 ml-1 cursor-pointer underline font-light"
      >
        neoJr+ Video
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgImageUrl: require("@/assets/images/icons/4.-Elementary-school.jpg"),
      isMobile: window.innerWidth <= 768, // Adjust the breakpoint as needed
      mobileImageUrl: require("@/assets/images/mobile/4.-Elementary-school-720x1280.jpg"),
      desktopImageUrl: require("@/assets/images/icons/4.-Elementary-school.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>