<template>
  <div>
    <div
      class="h-screen md:h-75vh lg:h-screen flex items-center justify-center"
    >
      <div
        class="w-full md:w-1024 py-14 mx-auto flex flex-col md:flex-col items-center md:items-center justify-end"
      >
        <img
          class="w-36"
          src="@/assets/images/DynEd-Logo-Final_0_0.png"
          alt="Group1608"
        />
        <div class="w-full md:w-8/12 px-4 md:px-0 mt-10">
          <h3 class="text-2xl font-black">
            DynEd Student Certificate Verification
          </h3>
          <p class="w-full text-justify py-4">
            Because our students work hard to achieve their certified English
            skills, DynEd works hard to ensure others don’t take the easy road
            to certification. You can confirm if the certificate you are holding
            belongs to a DynEd student that has been certified by our
            institution by entering the certificate identification code in the
            space below. <br />
            To verify a neo student’s Statement of Results, visit
            <a
              target="_blank"
              href="https://neo-verify.com/"
              class="text-primary underline"
              >neo-verify.com.</a
            ><br />
            Enter a certificate ID:
          </p>
          <input
            class="w-8/12 p-2 rounded-sm border border-softGrey"
            type="text"
            v-model="cert_id"
            placeholder=""
          />
          <div class="text-red text-sm">{{ verifStatus }}</div>
          <RoundedButtonVue
            v-if="cert_id == ''"
            title="Verify"
            bgColor="bg-grey"
            textColor="text-white"
            width="w-max"
            class="mt-4 cursor-not-allowed"
          />
          <div v-else>
            <RoundedButtonVue
              v-if="!toggleLoader"
              title="Verify"
              bgColor="bg-primary"
              textColor="text-white"
              width="w-max"
              class="mt-4"
              @click.native="getCert"
            />
            <LoaderVue v-else />
          </div>
          <div v-if="gettersDynedCert" class="mt-4">
            <ul v-if="!gettersDynedCert.error">
              <li class="font-bold">Certificate ID: <span class="text-secondary">{{ gettersDynedCert.shortUUID }}</span></li>
              <li class="mt-1 font-bold">Student Name: <span class="text-secondary">{{ gettersDynedCert.nameFull }}</span></li>
              <li class="my-1 font-bold">Certificate Level: <span class="text-secondary">{{ gettersDynedCert.level }}</span></li>
              <li class="font-bold">Date Awarded: <span class="text-secondary">{{ gettersDynedCert.dateIssue }}</span></li>
            </ul>
            <span class="font-bold">{{ gettersDynedCert.error }}</span>
          </div>
          <p class="text-sm pt-4">
            If you were unable to verify the certificate or encountered an
            error, please contact DynEd’s certification department at
            <a href="mailto:verify@dyned.com" class="text-primary underline"
              >verify@dyned.com.</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoundedButtonVue from "../components/buttons/RoundedButton.vue";
import BasicModalsVue from "../components/modals/BasicModals.vue";
import ProficiencyLevelComponentVue from "../components/proficiencylevel/ProficiencyLevelComponent.vue";
import CertificateIconVue from "../components/svgicons/CertificateIcon.vue";
import CloseIconVue from "../components/svgicons/CloseIcon.vue";
import LoaderVue from "../components/loader/Loader.vue";

import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default {
  components: {
    RoundedButtonVue,
    BasicModalsVue,
    CloseIconVue,
    CertificateIconVue,
    ProficiencyLevelComponentVue,
    LoaderVue,
  },
  data() {
    return {
      showCertificationModal: false,
      showCertificateResult: false,
      showViewCertificationModal: false,
      cert_id: "",
      toggleLoader: false,
      verifStatus: "",
    };
  },
  methods: {
    ...mapMutations("moduleGetCertification", ["clearDynedState"]),
    closeModals() {
      this.showViewCertificationModal = false;
      this.toggleLoader = false;
      this.cert_id = "";
      this.$store.commit("moduleGetCertification/clearState");
    },
    openCertificationModal() {
      this.showViewCertificationModal = true;
    },
    getCert() {
      this.toggleLoader = true;
      this.$store.dispatch("moduleGetCertification/getDynedCertification", {
        cert_id: this.cert_id,
      });
    },
  },
  created() {
    this.clearDynedState();
  },
  computed: {
    ...mapGetters("moduleGetCertification", {
      gettersDynedCert: "gettersDynedCert",
    }),
  },
  watch: {
    gettersDynedCert(i) {
      if (i.status == 200) {
        this.openCertificationModal();
        this.toggleLoader = false;
        this.verifStatus = "";
      } else {
        this.toggleLoader = false;
        this.verifStatus = i.message;
      }
    },
  },
};
</script>

<style>
</style>