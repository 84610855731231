import axios from 'axios'

const moduleGetCertification = {
    namespaced: true,
    state: () => ({
        stateCert: '',
        stateDynedCert: ''
    }),
    mutations: {
        muttCert(state, muttCert) {
            state.stateCert = muttCert
        },
        clearState(state) {
            state.stateCert = ''
        },
        muttDynedCert(state, muttDynedCert) {
            state.stateDynedCert = muttDynedCert
        },
        clearDynedState(state) {
            state.stateDynedCert = ''
        },
    },
    actions: {
        getCertification({ commit, dispatch }, { cert_id }) {
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_CERTAPI_BASEURL}/api/v1/certificate/check/${cert_id}`,
                headers: {
                    'Accept': '*/*'
                }
            })
                .then(res => {
                    commit('muttCert', {
                        data: res
                    })
                    // console.log(res);
                }).catch(err => {
                    commit('muttCert', {
                        data: err.response.data
                    })
                    console.log(err)
                })
        },
        getDynedCertification({ commit, dispatch }, { cert_id }) {
            const maxRetries = 3;
            let attempts = 0;
        
            const fetchWithRetry = async () => {
                attempts++;
                try {
                    const res = await axios({
                        method: 'GET',
                        url: `${process.env.VUE_APP_CERT_VERIFY_URL}/cert-verify/${cert_id}`,
                        headers: {
                            'Accept': '*/*'
                        }
                    });
                    commit('muttDynedCert', {
                        data: res.data
                    });
                    
                } catch (err) {
                    if (attempts < maxRetries) {
                        console.log(`Attempt ${attempts} failed. Retrying...`);
                        return fetchWithRetry(); // Retry the fetch
                    } else {
                        commit('muttDynedCert', {
                            data: err.response ? err.response.data : { error: 'Certificate not found.' }
                        });
                        console.error('Max retries reached. Error:', err);
                    }
                }
            };
        
            fetchWithRetry();
        },
    },
    getters: {
        gettersCert: state => {
            const data = state.stateCert

            if (data == '') {
                return false
            } else {
                return data.data
            }
        },
        gettersDynedCert: state => {
            const data = state.stateDynedCert

            if (data == '') {
                return false
            } else {
                return data.data
            }
        },
    }
}
const initialState = {
    stateCert: '',
    stateDynedCert: ''
};

export default moduleGetCertification